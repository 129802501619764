import { React } from 'react';
import FiltersProvider from '@accrosoft-ltd/vf-careers-site-theme/src/context/FiltersContext';
import I18nProvider from '@accrosoft-ltd/vf-careers-site-theme/src/i18n/i18n';
import '@fontsource/roboto';

export const onClientEntry = async () => {
  require('babel-polyfill');
  require('whatwg-fetch');

  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
};

export const wrapRootElement = ({ element }) => (
  <I18nProvider>
    <FiltersProvider>{element}</FiltersProvider>
  </I18nProvider>
);
